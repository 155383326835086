import {ASSETS} from "../common/images";
import * as React from "react";

const IosDevelopment = () => {
    return (
        <div className="row d-flex align-items-center common-services-details-sec-inner">
            <div className="col-md-6">
                <div className="common-col-description services-details-left-col">
                    <h3 className="gb-color-black mb-md-4 mb-3 common-sec-title"><span
                        className="gb-color-red">iOS</span> app development <br />services
                    </h3>
                    <p className="gb-description-small gb-color-black mb-md-4 mb-3">IOS App Development is the cornerstone for businesses to stay ahead in the marketing game. Taking this as our iOS App Development is the cornerstone for businesses to stay ahead in the marketing game. Taking this as our...</p>
                </div>
            </div>
            <div className="col-md-6">
                <div className="common-col-img services-details-right-col">
                    <img src={ASSETS.SERVICES.IosApp} alt="hybrid app" />
                </div>
            </div>
        </div>
    )
}
export default IosDevelopment;