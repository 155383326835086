import {ASSETS} from "../common/images";
import * as React from "react";

const MobileAppDevelopment = () => {
    return (
        <div className="row d-flex align-items-center common-services-details-sec-inner flex-column-reverse flex-md-row">
            <div className="col-md-6">
                <div className="common-col-img services-details-left-col">
                    <span className="shape-1" />
                    <span className="shape-2" />
                    <img src={ASSETS.HOME.CommonHeaderShape6} className="shape-3" alt="squre shape" />
                    <img src={ASSETS.SERVICES.HybridMobileApp} alt="hybrid app" className="mb-3 mb-md-0" />
                </div>
            </div>
            <div className="col-md-6">
                <div className="common-col-description services-details-right-col">
                    <h3 className="gb-color-black mb-md-4 mb-3 common-sec-title"><span
                        className="gb-color-red">Hybrid</span> Mobile Aap <br />Development
                    </h3>
                    <p className="gb-description-small gb-color-black mb-md-4 mb-3">Hybrid mobile app development Hybrid App development is a solitary app that can be utilized in Android, IOS, and windows.</p>
                    <p className="gb-description-small gb-color-black mb-md-4 mb-3">We use the latest technology and we never stop learning and that's why model sentence structures</p>
                </div>
            </div>
        </div>
    )
}
export default MobileAppDevelopment;