import * as React from "react";
import {ASSETS} from "../common/images";
import Breadcrumbs from "../common/breadcrumbs";

const Banner = () => {
    return (
 <section className="gb-banner-wrapper gb-services-banner">
            <span className="common-header-shape-1" />
            <span className="common-header-shape-3" />
            <img src={ASSETS.HOME.CommonHeaderShape4} className="common-header-shape-4" alt="common-header-shape-4" />
            <span className="common-header-shape-5" />
            <span className="common-header-shape-6" />
            <span className="common-header-shape-7" />
            <span className="common-header-shape-8" />
            <span className="common-header-shape-9" />
            <img src={ASSETS.HOME.CommonHeaderShape6} className="common-header-shape-10" alt="common-header-shape-10" />
            <span className="common-header-shape-11" />
            <img src={ASSETS.HOME.CommonHeaderShape12} className="common-header-shape-12" alt="common-header-shape-12" />
            <div className="container">
                <Breadcrumbs pageTitle={'Services'} currentPage={'Services'} />
                <div className="row d-flex align-items-center">
                    <div className="col-md-6 mb-3 mb-md-0">
                        <div className="gb-services-common-listing-wrapper">
                            <div className="row d-flex align-items-center">
                                <div className="col-lg-5 col-sm-6 col-7">
                                    <div className="gb-services-listing">
                                        <p className="title">Mobile App Development</p>
                                        <ul>
                                            <li>
                                                <span className="gb-service-icon"><img src={ASSETS.SERVICES.Swift} alt="services icon" /></span>
                                                <span className="gb-service-name">Swift,Objective C</span>
                                            </li>
                                            <li>
                                                <span className="gb-service-icon"><img src={ASSETS.SERVICES.Java} alt="services icon" /></span>
                                                <span className="gb-service-name">Java,Kotline</span>
                                            </li>
                                            <li>
                                                <span className="gb-service-icon"><img src={ASSETS.SERVICES.Flutter} alt="services icon" /></span>
                                                <span className="gb-service-name">Flutter</span>
                                            </li>
                                            <li>
                                                <span className="gb-service-icon"><img src={ASSETS.SERVICES.ReactNative} alt="services icon" /></span>
                                                <span className="gb-service-name">React Native</span>
                                            </li>
                                            <li>
                                                <span className="gb-service-icon"><img src={ASSETS.SERVICES.PhoneGap} alt="services icon" /></span>
                                                <span className="gb-service-name">Phone Gap</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-7 col-sm-6 col-5">
                                    <div className="services-img">
                                        <img src={ASSETS.SERVICES.MobileServices} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-3 mb-md-0">
                        <div className="gb-services-common-listing-wrapper">
                            <div className="row d-flex align-items-center">
                                <div className="col-lg-5 col-sm-6 col-7">
                                    <div className="gb-services-listing">
                                        <p className="title">Web Development</p>
                                        <ul>
                                            <li>
                                                <span className="gb-service-icon"><img src={ASSETS.SERVICES.Node} alt="services icon" /></span>
                                                <span className="gb-service-name">Node Js</span>
                                            </li>
                                            <li>
                                                <span className="gb-service-icon"><img src={ASSETS.SERVICES.Symfony} alt="services icon" /></span>
                                                <span className="gb-service-name">Symfony</span>
                                            </li>
                                            <li>
                                                <span className="gb-service-icon"><img src={ASSETS.SERVICES.Laravel} alt="services icon" /></span>
                                                <span className="gb-service-name">Laravel</span>
                                            </li>
                                            <li>
                                                <span className="gb-service-icon"><img src={ASSETS.SERVICES.WordPress} alt="services icon" /></span>
                                                <span className="gb-service-name">WordPress</span>
                                            </li>
                                            <li>
                                                <span className="gb-service-icon"><img src={ASSETS.SERVICES.React} alt="services icon" /></span>
                                                <span className="gb-service-name">React JS</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-7 col-sm-6 col-5">
                                    <div className="services-img">
                                        <img src={ASSETS.SERVICES.DesktopServices} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Banner;
