import {ASSETS} from "../common/images";
import * as React from "react";

const AndroidAppDevelopment = () => {
    return (
        <div className="row d-flex align-items-center common-services-details-sec-inner flex-column-reverse flex-md-row">
        <div className="col-md-6">
        <div className="common-col-img services-details-left-col">
        <img src={ASSETS.SERVICES.AndroidApp} alt="hybrid app" />
        </div>
        </div>
        <div className="col-md-6">
    <div className="common-col-description services-details-right-col">
    <h3 className="gb-color-black mb-md-4 mb-3 common-sec-title"><span
        className="gb-color-red">Android</span> development <br />services
    </h3>
    <p className="gb-description-small gb-color-black mb-md-4 mb-3">We are the front runners in Android app development, providing high-quality android development services. Our team uses the latest technology and tools to provide an amazing user experience. We develop for mobile, tablets and wearables - we’ve done it all. Our team is highly trained and updated with the latest trends in the Android field. Our team uses Kotlin, the fastest and concise language, to develop Android apps. Since the announcement of Google of adding Kotlin to official languages, we have adopted it and results are impeccable in terms of app quality and time taken to develop the app. For your app to be scalable in future, architecture is designed as per the Android architecture guidelines. UI and UX teams work in hand to hand following Android's material design guidelines resulting in user-friendly apps.</p>
    </div>
    </div>
    </div>
    )
}
export default AndroidAppDevelopment;