import {ASSETS} from "../../common/images";
import * as React from "react";

const ServicesList = (props) => {
    const {title1, title2, icon} = props;

    return (
        <div className="service-common-sec mb-3 mb-md-5">
            <img src={icon} alt="common service icon" className="common-services-icon wow swing" data-wow-delay="0.5s" />
            <p className="common-services-title">{title1} <br/>{title2}</p>
        </div>
    )
}
export default ServicesList;