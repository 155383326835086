import ServicesList from "../home/our-services/services-list";
import * as React from "react";
import {ASSETS} from "../common/images";

const WhatWeDo = () => {
    const servicesList = [
        {
         title1: 'Web Design',
         title2: '& Development',
         icon: ASSETS.SERVICES.WhatWeIcon1
        },
        {
         title1: 'Mobile',
         title2: 'Application',
         icon: ASSETS.SERVICES.WhatWeIcon2
        },
        {
         title1: 'Graphic',
         title2: 'Design',
         icon: ASSETS.SERVICES.WhatWeIcon3
        },
        {
         title1: 'Internet',
         title2: 'Marketing',
         icon: ASSETS.SERVICES.WhatWeIcon4
        }
    ]
    return (
        <section className="services-what-we-do-sec-wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="gb-color-black mb-md-5 mb-4 common-sec-title text-center"><span
                            className="gb-color-red">OUR SERVICES</span> <br />What We Offer
                        </h2>
                    </div>
                </div>
                <div className="row">
                    {servicesList.map( (row,index) => (
                        <div className="col-md-3 col-6" key={'what-we-do-'+index}>
                            <ServicesList title1={row.title1} title2={row.title2} icon={row.icon} />
                        </div>
                    ) )}
                </div>
            </div>
        </section>
    )
}
export default WhatWeDo;